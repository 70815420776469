<template>
  <div class="header neurial-grotesk">
    <div class="header-logo">
      <img src="/images/eldorado_logo.png" alt="ElDorado Logo" />
    </div>

    <div class="absolute right-5 md:right-10 top-5 block md:hidden z-50 mobile-icons-container">
      <div class="relative flex row">
        <router-link v-if="wallets.length > 0" to="/wallet">
          <div class="pointer mr-8">
            <v-icon icon="mdi-wallet" size="medium" color="white" />
          </div>
        </router-link>
        <div
          v-else
          class="pointer mr-8"
          role="button"
          tabindex="0"
          aria-label="Connect your crypto wallet"
          @click="connectWalletIsVisible = true"
        >
          <v-icon icon="mdi-wallet" size="large" color="white" />
        </div>
        <div
          class="pointer"
          role="button"
          tabindex="0"
          aria-label="Open the navigation menu"
          @click="menuToggle = true"
        >
          <v-icon icon="mdi-menu" size="large" color="white" />
        </div>
      </div>
    </div>
    <div
      v-if="menuToggle"
      class="absolute left-0 top-0 z-50"
      :class="menuToggle ? 'block' : 'hidden'"
    >
      <div id="myNav" class="fixed w-full h-full bg-mobile-menu bg-opacity-75">
        <a
          class="close-btn text-white text-3xl absolute top-4 right-4"
          role="button"
          tabindex="0"
          aria-label="Close the navigation menu"
          @click="menuToggle = false"
        >
          &times;
        </a>
        <div class="flex flex-col items-center h-full mobile-menu">
          <a class="text-white text-2xl py-4">Trade</a>
          <router-link to="/swap" class="button">Swap</router-link>
          <!-- <router-link to="/quests" class="button">Quests</router-link> -->
          <a class="text-white text-2xl py-4">Earn</a>
          <router-link to="/add" class="button">Add</router-link>
          <router-link to="/withdraw" class="button">Withdraw</router-link>
          <router-link to="/savers" class="button">Savers</router-link>
          <router-link to="/stats" class="button">Stats</router-link>
          <a class="text-white text-2xl py-4">Learn</a>
          <a :href="links['Beginner\'s Guide']" target="_blank" rel="noreferrer" class="button">
            Beginner&apos;s Guide
          </a>
          <a :href="links['El Dorado Gitbook']" target="_blank" rel="noreferrer" class="button">
            El Dorado Gitbook
          </a>
          <a :href="links['Maya Protocol']" target="_blank" rel="noreferrer" class="button">
            Maya Protocol
          </a>
          <a :href="links['Chainflip Protocol']" target="_blank" rel="noreferrer" class="button">
            Chainflip Protocol
          </a>
          <a class="text-white text-2xl py-4">Buy/Sell</a>
          <router-link to="/Buy">
            <a class="button">Kado</a>
          </router-link>
        </div>
      </div>
    </div>

    <div class="menu-container">
      <div class="menu-area mobile">
        <ul>
          <li v-for="(areaMenu, index) in areaMenus" :key="index">
            <router-link :to="`/${targetAddr[index]}`">{{ areaMenu }}</router-link>
            <v-icon v-if="subMenus[index].length > 0" icon="mdi-chevron-down" />
            <template v-if="subMenus[index].length > 0">
              <ul class="sub-menu" :class="subMenuClassName(areaMenu)">
                <li v-for="subMenu in subMenus[index]" :key="subMenu">
                  <a v-if="isExternal(links[subMenu])" :href="links[subMenu]" target="_blank">{{
                    subMenu
                  }}</a>
                  <router-link v-else :to="`${subMenu.toLowerCase()}`">{{ subMenu }}</router-link>
                </li>
              </ul>
            </template>
          </li>
        </ul>
      </div>
    </div>
    <ConnectWallet />
  </div>
</template>
<script setup lang="ts">
import { useWalletsStore } from '@/store/walletsStore'
import { useConnectWalletModalStore } from '@/store/connectWalletModalStore'
const walletsStore = useWalletsStore()
const { wallets } = storeToRefs(walletsStore)
const connectWalletModalStore = useConnectWalletModalStore()
const { connectWalletIsVisible } = storeToRefs(connectWalletModalStore)
const menuToggle = ref(false)
const areaMenus = ['Swap', 'Earn', 'Stats', 'Learn', 'Buy/Sell']
const route = useRoute()
const subMenus = [
  [],
  ['Add', 'Withdraw', 'Savers'],
  [],
  ["Beginner's Guide", 'El Dorado Gitbook', 'Maya Protocol', 'Chainflip Protocol'],
  [],
  [],
]
const targetAddr = ['swap', 'add', 'stats', 'swap', 'buy']
type LinkType = {
  [key: string]: string
}
const links: LinkType = {
  "Beginner's Guide":
    'https://el-dorado.gitbook.io/el-dorado/introduction-to-el-dorado/beginners-guide-zero-to-one',
  'El Dorado Gitbook': 'https://el-dorado.gitbook.io/el-dorado/introduction-to-el-dorado/vision',
  'Maya Protocol': 'https://docs.mayaprotocol.com/',
  'Chainflip Protocol': 'https://chainflip.io/vision',
}

function subMenuClassName(subMenu: string) {
  return subMenu.toLowerCase() + '-menu'
}

function isExternal(link: string) {
  return link && link.startsWith('http')
}

watch(
  () => route.path,
  (newPath, oldPath) => {
    if (newPath !== oldPath) menuToggle.value = false
  },
)
</script>
<style scoped>
.header-logo {
  position: fixed;
  width: 90px;
  z-index: 30;
}

.menu-area {
  position: fixed;
  z-index: 33;
}
.menu-area ul {
  display: flex;
  border-radius: 8px;
  background: var(--dark-00);
  position: relative;
  z-index: 99999999999999;
  padding: 4px 0px;
  margin-top: 6px;
}
.menu-area .sub-menu {
  display: none;
}
.menu-area ul li:hover .sub-menu {
  display: flex;
  z-index: 999999999999999;
  flex-direction: column;
  position: absolute;
  margin-top: 0px;
  overflow-y: clip;
}

.menu-area ul > li {
  position: relative;
  z-index: 99999999999999;
  margin: 0 16px;
}
.menu-area ul li i {
  color: white;
  margin: 0px 0px 2px 8px;
}

.menu-area ul li > a {
  color: white;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 32px;
}
.menu-area ul li a:hover {
  color: var(--yellow-0);
}

.menu-area ul li svg {
  display: inline;
  margin-left: 8px;
}

.menu-container {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
}
.sub-menu li a {
  display: flex;
  width: 100%;
}

.sub-menu.learn-menu {
  width: 323px;
}
.sub-menu.onramp-menu {
  width: 136px;
}
.sub-menu li:nth-child(even) {
  background: var(--dark-0);
  padding: 0 16px;
  margin: 0;
}
.sub-menu li:hover .link_animate_3 {
  transform: scaleX(1);
}
.menu-area > ul > li > .sub-menu > li .hide {
  position: absolute;
  width: 0;
  height: 0;
  visibility: hidden;
}
.mobile-icons-container {
  display: none;
}
@media only screen and (max-width: 767px) {
  .header-logo {
    position: absolute;
    margin: 16px;
  }
  .header {
    padding: 0px;
  }
  .close-btn {
    font-size: 32px;
    right: 0;
    margin: 0 16px;
  }
  .mobile-icons-container {
    display: flex;
    right: 0;
    padding: 16px;
  }
}
</style>
