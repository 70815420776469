<template>
  <div>
    <div v-if="isWalletConnected" class="connected-button-container">
      <button class="connect-button" data-testid="wallet" @click="navigateTo('wallet')">
        Wallet
      </button>
      <button class="disconnect-button" @click="resetWallet">Disconnect</button>
    </div>
    <button
      v-if="!isWalletConnected"
      class="connect-button"
      data-testid="connect-wallet"
      @click="showConnectWalletModal"
    >
      Connect Wallet
    </button>

    <v-dialog v-model="connectWalletIsVisible" width="auto">
      <div
        class="connect-wallet-modal modal-content"
        style="justify-content: center; width: auto; margin: 10px; padding: 1px"
      >
        <!-- TODO: we need to convert this to a computed prop or something -->
        <div v-if="showTabs">
          <div class="wallets">
            <h4 class="title">Select your wallet</h4>
            <!-- Xdefi -->
            <div
              class="wallet"
              :class="{ selected: selectedWallet == 'xdefi' }"
              role="button"
              tabindex="0"
              aria-label="Connect xdefi wallet"
              @click="tab = 'xdefi'"
            >
              <img src="/images/xdefi_white.svg" alt="xDefi" height="28" width="212" />
            </div>
            <!-- Talisman -->
            <div
              class="wallet"
              :class="{ selected: selectedWallet == 'talisman' }"
              role="button"
              tabindex="0"
              aria-label="Connect talisman wallet"
              @click="tab = 'talisman'"
            >
              <div>
                <img src="/images/talisman.svg" alt="Talisman" height="28" />
              </div>
            </div>
            <!-- Phantom -->
            <div
              class="wallet"
              :class="{ selected: selectedWallet == 'phantom' }"
              role="button"
              tabindex="0"
              aria-label="Connect phantom wallet"
              @click="tab = 'phantom'"
            >
              <div>
                <img src="/images/phantom.svg" alt="Phantom" height="28" />
              </div>
            </div>
            <!-- Keystore -->
            <div
              class="wallet"
              :class="{ selected: tab == 'load-keystore' }"
              role="button"
              tabindex="0"
              aria-labelledby="wallet-connect-keystore"
              @click="tab = 'load-keystore'"
            >
              <div>
                <v-icon icon="mdi-file-document-outline" color="white" />
                <h4 id="wallet-connect-keystore">Keystore</h4>
              </div>
            </div>
            <!-- New Keystore -->
            <div
              class="wallet"
              :class="{ selected: tab === 'new-keystore' }"
              role="button"
              tabindex="0"
              aria-labelledby="wallet-create-new-wallet"
              @click="tab = 'new-keystore'"
            >
              <div>
                <v-icon icon="mdi-plus-circle-outline" color="white" />
                <h4 id="wallet-create-new-wallet">Create new wallet</h4>
              </div>
            </div>
            <!-- Recover Keystore -->
            <div
              class="wallet"
              :class="{ selected: tab === 'recover-keystore' }"
              role="button"
              tabindex="0"
              aria-labelledby="wallet-create-keystore-wallet"
              @click="tab = 'recover-keystore'"
            >
              <div>
                <v-icon icon="mdi-key-outline" color="white" />
                <h4 id="wallet-create-keystore-wallet">Create keystore wallet from phrase</h4>
              </div>
            </div>
          </div>
        </div>

        <!-- New Keystore -->
        <form v-if="tab === 'new-keystore'" @submit.prevent>
          <div className="modal-content">
            <h1 className="modal-title">Create new keystore</h1>
            <p className="text-white">Enter a password to protect your new seed phrase</p>
            <input
              v-model="createNewKeystorePassword"
              autoFocus
              placeholder="Password"
              type="password"
              className="input mb-4"
            />
            <button className="button w-full" type="submit" @click="createAndDownloadKeystore">
              Create
            </button>
          </div>
        </form>

        <!-- New Keystore Success -->
        <div
          v-if="tab === 'new-keystore-success'"
          style="max-width: 386px; align-self: auto; padding: 1rem"
        >
          <div className="">
            <h1 className="modal-title">Create new keystore</h1>
            <p className="text-white mb-4">
              Your keystore was just created and downloaded. Write down the following seed phrase on
              a piece of paper and store it securely. It&apos;s your backup in case you lose the
              keystore file.
            </p>
            <label class="text-white"
              >Click to copy words to clipboard:
              <textarea
                data-testid="keystore-mnemonic"
                :value="createNewKeystoreMnemonic"
                class="text-white mt-4 mb-4 w-full"
                style="resize: none"
                readonly
                rows="5"
                data-private
                @focus="selectMnemonic"
              />
            </label>
            <button className="button button-secondary w-full" @click="closeConnectWallet">
              Close
            </button>
          </div>
        </div>

        <!-- Xdefi and existing Keystore -->
        <!-- TODO: we need to refactor this into a computed prop. -->
        <div v-if="showTabs" class="chains">
          <div class="title row">
            <h4>Select blockchain to connect</h4>
          </div>
          <div class="overflow-auto">
            <div class="chain-icons" style="margin: 0px; width: max-content">
              <!-- Maya -->
              <span
                :class="getChainIconClass('MAYA')"
                role="checkbox"
                tabindex="0"
                :aria-checked="
                  isChainSupportedByWallet(selectedWallet, 'MAYA') &&
                  selectedChains.includes('MAYA')
                "
                aria-label="Click to select Mayachain to connect"
                :aria-disabled="!isChainSupportedByWallet(selectedWallet, 'MAYA')"
                @click="toggleSelectedChain('MAYA')"
              >
                <img src="/tokens/maya.png?url" alt="Maya" height="32" width="32" />
                <v-icon v-if="isConnected('MAYA')" icon="mdi-connection" size="x-small" />
                <v-icon
                  v-else-if="
                    isChainSupportedByWallet(selectedWallet, 'MAYA') &&
                    selectedChains.includes('MAYA')
                  "
                  icon="mdi-check"
                  size="x-small"
                />
              </span>
              <!-- Thorchain -->
              <span
                :class="getChainIconClass('THOR')"
                role="button"
                tabindex="0"
                :aria-checked="
                  isChainSupportedByWallet(selectedWallet, 'THOR') &&
                  selectedChains.includes('THOR')
                "
                aria-label="Click to select Thorchain to connect"
                :aria-disabled="!isChainSupportedByWallet(selectedWallet, 'THOR')"
                @click="toggleSelectedChain('THOR')"
              >
                <img src="/tokens/rune.png?url" alt="Rune" height="32" width="32" />
                <v-icon v-if="isConnected('THOR')" icon="mdi-connection" size="x-small" />
                <v-icon
                  v-else-if="
                    isChainSupportedByWallet(selectedWallet, 'THOR') &&
                    selectedChains.includes('THOR')
                  "
                  icon="mdi-check"
                  size="x-small"
                />
              </span>
              <!-- Bitcoin -->
              <span
                :class="getChainIconClass('BTC')"
                role="button"
                tabindex="0"
                :aria-checked="
                  isChainSupportedByWallet(selectedWallet, 'BTC') && selectedChains.includes('BTC')
                "
                aria-label="Click to select Bitcoin to connect"
                :aria-disabled="!isChainSupportedByWallet(selectedWallet, 'BTC')"
                @click="toggleSelectedChain('BTC')"
              >
                <img src="/tokens/btc.png?url" alt="BTC" height="32" width="32" />
                <v-icon v-if="isConnected('BTC')" icon="mdi-connection" size="x-small" />
                <v-icon
                  v-else-if="
                    isChainSupportedByWallet(selectedWallet, 'BTC') &&
                    selectedChains.includes('BTC')
                  "
                  icon="mdi-check"
                  size="x-small"
                />
              </span>
              <!-- Ethereum -->
              <span
                :class="getChainIconClass('ETH')"
                role="button"
                tabindex="0"
                :aria-checked="
                  isChainSupportedByWallet(selectedWallet, 'ETH') && selectedChains.includes('ETH')
                "
                aria-label="Click to select Ethereum to connect"
                :aria-disabled="!isChainSupportedByWallet(selectedWallet, 'ETH')"
                @click="toggleSelectedChain('ETH')"
              >
                <img src="/tokens/ethereum.png?url" alt="ETH" height="32" width="32" />
                <v-icon v-if="isConnected('ETH')" icon="mdi-connection" size="x-small" />
                <v-icon
                  v-else-if="
                    isChainSupportedByWallet(selectedWallet, 'ETH') &&
                    selectedChains.includes('ETH')
                  "
                  icon="mdi-check"
                  size="x-small"
                />
              </span>
              <!-- Arbitrum -->
              <span
                :class="getChainIconClass('ARB')"
                role="button"
                tabindex="0"
                :aria-checked="
                  isChainSupportedByWallet(selectedWallet, 'ARB') && selectedChains.includes('ARB')
                "
                aria-label="Click to select Arbitrum to connect"
                :aria-disabled="!isChainSupportedByWallet(selectedWallet, 'ARB')"
                @click="toggleSelectedChain('ARB')"
              >
                <img src="/tokens/arbitrum.png?url" alt="ARB" height="32" width="32" />
                <v-icon v-if="isConnected('ARB')" icon="mdi-connection" size="x-small" />
                <v-icon
                  v-else-if="
                    isChainSupportedByWallet(selectedWallet, 'ARB') &&
                    selectedChains.includes('ARB')
                  "
                  icon="mdi-check"
                  size="x-small"
                />
              </span>
              <!-- Kujira -->
              <span
                :class="getChainIconClass('KUJI')"
                role="button"
                tabindex="0"
                :aria-checked="
                  isChainSupportedByWallet(selectedWallet, 'KUJI') &&
                  selectedChains.includes('KUJI')
                "
                aria-label="Click to select Kujira to connect"
                :aria-disabled="!isChainSupportedByWallet(selectedWallet, 'KUJI')"
                @click="toggleSelectedChain('KUJI')"
              >
                <img src="/tokens/kuji.png?url" alt="KUJI" height="32" width="32" />
                <v-icon v-if="isConnected('KUJI')" icon="mdi-connection" size="x-small" />
                <v-icon
                  v-else-if="
                    isChainSupportedByWallet(selectedWallet, 'KUJI') &&
                    selectedChains.includes('KUJI')
                  "
                  icon="mdi-check"
                  size="x-small"
                />
              </span>
              <!-- Dash -->
              <span
                :class="getChainIconClass('DASH')"
                role="button"
                tabindex="0"
                :aria-checked="
                  isChainSupportedByWallet(selectedWallet, 'DASH') &&
                  selectedChains.includes('DASH')
                "
                aria-label="Click to select Dash to connect"
                :aria-disabled="!isChainSupportedByWallet(selectedWallet, 'DASH')"
                @click="toggleSelectedChain('DASH')"
              >
                <img src="/tokens/dash.png?url" alt="DASH" height="32" width="32" />
                <v-icon v-if="isConnected('DASH')" icon="mdi-connection" size="x-small" />
                <v-icon
                  v-else-if="
                    isChainSupportedByWallet(selectedWallet, 'DASH') &&
                    selectedChains.includes('DASH')
                  "
                  icon="mdi-check"
                  size="x-small"
                />
              </span>
              <!-- Polkadot -->
              <span
                :class="getChainIconClass('POLKADOT')"
                role="button"
                tabindex="0"
                :aria-checked="
                  isChainSupportedByWallet(selectedWallet, 'POLKADOT') &&
                  selectedChains.includes('POLKADOT')
                "
                aria-label="Click to select Polkadot to connect"
                :aria-disabled="!isChainSupportedByWallet(selectedWallet, 'POLKADOT')"
                @click="toggleSelectedChain('POLKADOT')"
              >
                <img src="/tokens/polkadot.svg?url" alt="DASH" height="32" width="32" />
                <v-icon v-if="isConnected('POLKADOT')" icon="mdi-connection" size="x-small" />
                <v-icon
                  v-else-if="
                    isChainSupportedByWallet(selectedWallet, 'POLKADOT') &&
                    selectedChains.includes('POLKADOT')
                  "
                  icon="mdi-check"
                  size="x-small"
                />
              </span>
              <!-- Solana -->
              <span
                :class="getChainIconClass('SOLANA')"
                role="button"
                tabindex="0"
                :aria-checked="
                  isChainSupportedByWallet(selectedWallet, 'SOLANA') &&
                  selectedChains.includes('SOLANA')
                "
                aria-label="Click to select Solana to connect"
                :aria-disabled="!isChainSupportedByWallet(selectedWallet, 'SOLANA')"
                @click="toggleSelectedChain('SOLANA')"
              >
                <img src="/tokens/solana.png?url" alt="SOLANA" height="32" width="32" />
                <v-icon v-if="isConnected('SOLANA')" icon="mdi-connection" size="x-small" />
                <v-icon
                  v-else-if="
                    isChainSupportedByWallet(selectedWallet, 'SOLANA') &&
                    selectedChains.includes('SOLANA')
                  "
                  icon="mdi-check"
                  size="x-small"
                />
              </span>
            </div>
          </div>
          <div v-if="selectedWallet == 'keystore'" class="keystore-file-input-password-container">
            <label htmlFor="file-upload" class="keystore-input pointer">
              {{ keyStoreFileName == '' ? 'Select keystore file' : keyStoreFileName }}
            </label>
            <input
              id="file-upload"
              type="file"
              style="display: none"
              @change="(e) => onKeyStoreFileChange(e)"
            />
            <input
              v-model="keystorePassword"
              autoFocus
              class="keystore-input"
              placeholder="Password"
              type="password"
              data-private=""
            />
          </div>
          <button
            class="button w-full"
            :class="{ disabled: !canConnect() }"
            :disabled="!canConnect()"
            @click="connectWallet()"
          >
            Connect
          </button>
        </div>

        <!-- Recover Keystore -->
        <form v-if="tab === 'recover-keystore'" style="max-width: 386px" @submit.prevent>
          <div className="modal-content">
            <h1 className="modal-title">Recover keystore</h1>
            <p className="text-white">
              Enter your seed phrase to recover your keystore. This will create a new keystore file
              and download it.
            </p>
            <textarea
              v-model="recoverMnemonic"
              placeholder="Seed phrase"
              className="input mb-4"
              rows="3"
              data-private=""
            />
            <input
              v-model="recoverKeystorePassword"
              placeholder="Password"
              type="password"
              className="input mb-4"
              data-private=""
            />
            <button type="submit" className="button w-full" @click="recoverKeystore">
              Recover
            </button>
          </div>
        </form>
        <div class="wallets-mobile">
          <h4 class="title">Select your wallet</h4>
          <div class="wallets-mobile-row">
            <div
              class="wallet"
              :class="{ selected: tab == 'keystore' }"
              role="button"
              tabindex="0"
              aria-label="Click to connect a keystore"
              @click="tab = 'load-keystore'"
            >
              <div>
                <v-icon icon="mdi-file-document-outline" color="white" />
              </div>
              <span
                :class="{ connected: connectedWallets.includes('keystore'), 'connect-icon': true }"
              >
                <v-icon v-if="connectedWallets.includes('keystore')" icon="mdi-connection" />
                <v-icon v-else icon="mdi-check" />
              </span>
            </div>
            <div
              class="wallet"
              :class="{ selected: tab == 'new-keystore' }"
              role="button"
              tabindex="0"
              aria-label="Click to create a new keystore"
              @click="tab = 'new-keystore'"
            >
              <div>
                <v-icon icon="mdi-plus-circle-outline" color="white" />
              </div>
            </div>
            <div
              class="wallet"
              :class="{ selected: tab == 'recover-keystore' }"
              role="button"
              tabindex="0"
              aria-label="Click to restore a keystore from a phrase"
              @click="tab = 'recover-keystore'"
            >
              <div>
                <v-icon icon="mdi-key" color="white" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </v-dialog>
  </div>
</template>
<script setup lang="ts">
import { BrowserProvider } from 'ethers'
import {
  decryptFromKeystore,
  encryptToKeyStore,
  generatePhrase,
  validatePhrase,
} from '@xchainjs/xchain-crypto'
import { getWallets } from '@talismn/connect-wallets'
import type { Wallet as TalismanWallet, WalletAccount } from '@talismn/connect-wallets'
import { captureException } from '@sentry/browser'
import { useQuery } from '@tanstack/vue-query'
import { useConnectWalletModalStore } from '@/store/connectWalletModalStore'
import { useWalletsStore } from '@/store/walletsStore'
import { WALLETS } from '~/wallets/index'
import { useRouteStore } from '~/store/routeStore'
import { usePersister } from '~/utils/query'

const analytics = useAnalytics()
const runtimeConfig = useRuntimeConfig()
const DEV_KEYSTORE_PASSWORD = runtimeConfig.public.DEV_KEYSTORE_PASSWORD
const walletsStore = useWalletsStore()
const { setWallets, resetWalletState } = walletsStore
const { wallets, isChainConnected, isWalletConnected } = storeToRefs(walletsStore)

const refetchBalancesMs = parseInt(runtimeConfig.public.REFETCH_BALANCES_MS)
const persister = usePersister()
const balancesQuery = useQuery({
  queryKey: ['balances'],
  queryFn: walletsStore.updateAllBalances,
  persister,
  refetchInterval: Number.isNaN(refetchBalancesMs) ? 60000 : refetchBalancesMs,
})
const refetchBalancesIfEmpty = () => {
  // * only fetch balances if cache is empty
  if (balancesQuery.data.value) {
    return
  }
  return balancesQuery.refetch()
}

const connectWalletModalStore = useConnectWalletModalStore()
const { showConnectWalletModal, hideConnectWalletModal } = connectWalletModalStore
const { connectWalletIsVisible, connectedWallets } = storeToRefs(connectWalletModalStore)

const resetWallet = () => {
  resetWalletState()
  balancesQuery.refetch()
}

const selectedChains = ref<string[]>([])
// TODO: we can prob write this in a nicer way.
const selectedWallet = computed(() => {
  if (tab.value === 'xdefi') {
    return 'xdefi'
  } else if (tab.value === 'talisman') {
    return 'talisman'
  } else if (tab.value === 'phantom') {
    return 'phantom'
  } else if (tab.value === 'load-keystore') {
    return 'keystore'
  } else return ''
})

type Tab =
  | 'xdefi'
  | 'talisman'
  | 'phantom'
  | 'load-keystore'
  | 'new-keystore'
  | 'new-keystore-success'
  | 'recover-keystore'

const tab = ref<Tab>('xdefi')
const keyStoreFileName = ref('')
const keystore = ref('')
const keystorePassword = ref('')
const allSelected = ref(true)
const createNewKeystorePassword = ref('')
const createNewKeystoreMnemonic = ref('')
const recoverKeystorePassword = ref('')
const recoverMnemonic = ref('')

function isConnected(chain) {
  return wallets.value.some((wallet) => wallet.chain === chain)
}

const connectWallet = async () => {
  switch (selectedWallet.value) {
    case 'xdefi':
      return await onConnectSelectSubmitXDefiOnly(balancesQuery.refetch)
    case 'talisman':
      return await onConnectSelectSubmitTalisman()
    case 'keystore':
      return await onConnectKeyStoreSubmit({
        devMode: false,
        refetchBalances: balancesQuery.refetch,
      })
    case 'phantom':
      return await connectPhantom()
    default:
      captureException('Unknown wallet selected')
      throw new Error('Unknown wallet selected')
  }
}

const showTabs = computed(() => {
  const allowedTabs = ['xdefi', 'talisman', 'load-keystore', 'phantom']
  return allowedTabs.includes(tab.value)
})

function toggleSelectedChain(chain: Chain) {
  // Can't override connected
  if (isConnected(chain)) {
    return
  }
  // Can't select unsupported chains
  if (!isChainSupportedByWallet(selectedWallet.value, chain)) {
    return
  }
  // Add or remove chain from selected chains
  if (selectedChains.value.includes(chain)) {
    selectedChains.value = selectedChains.value.filter((c) => c !== chain)
  } else {
    selectedChains.value = [...selectedChains.value, chain]
  }
}

function canConnect() {
  return selectedChains.value.length > 0
}

watch([selectedWallet, connectWalletIsVisible], () => {
  selectAll(true)
})

function getChainIconClass(chain) {
  const isSupported = isChainSupportedByWallet(selectedWallet.value, chain)

  const isSelected = isSupported && selectedChains.value.includes(chain)
  // check if wallets contain a wallet with the chain
  const isConnected = wallets.value.some((wallet) => wallet.chain === chain)
  return `chain-icon ${!isConnected && isSelected ? 'selected' : ''} ${isSupported ? '' : 'disabled'}
      ${isConnected ? 'connected' : ''}`
}

function selectAll(select: Boolean) {
  const supportedChains = supportedChainsByWallet[selectedWallet.value]
  if (select && supportedChains) {
    const chainsToSelect = supportedChains.filter((chain) => !isConnected(chain))
    selectedChains.value = chainsToSelect
    allSelected.value = true
  } else {
    allSelected.value = false
    selectedChains.value = []
  }
}

onMounted(() => {
  const refetchBalancesOnConnect =
    runtimeConfig.public.REFETCH_BALANCES_ON_MOUNT === 'false' ? () => {} : balancesQuery.refetch

  if (localStorage.getItem('lastWallet') === 'xdefi') {
    selectAll(true)
    onConnectSelectSubmitXDefiOnly(refetchBalancesOnConnect)
  }
  if (localStorage.getItem('lastWallet') === 'keystore') {
    selectAll(true)
    if (DEV_KEYSTORE_PASSWORD && localStorage.getItem('keystore')) {
      keystore.value = localStorage.getItem('keystore')
      keystorePassword.value = DEV_KEYSTORE_PASSWORD
    }
    try {
      onConnectKeyStoreSubmit({ devMode: true, refetchBalances: refetchBalancesOnConnect })
    } catch (e) {
      captureException(e)
    }
  }
})

// TODO: move to wallets/keystore.ts
function onKeyStoreFileChange(e) {
  const file = e.target.files[0]
  keyStoreFileName.value = file.name
  const reader = new FileReader()
  reader.readAsText(file, 'UTF-8')
  reader.onload = (readerEvent) => {
    const content = readerEvent.target.result
    keystore.value = content
  }
}

// TODO: move to wallets/xdefi.ts
const onConnectSelectSubmitXDefiOnly = async (refetchBalances: () => void) => {
  try {
    if (
      !(
        window.xfi &&
        window.xfi.bitcoin &&
        window.xfi.ethereum &&
        window.xfi.thorchain &&
        window.xfi.mayachain &&
        window.xfi.keplr
      )
    ) {
      alert('unable to connect to xDefi')
      return
    }

    const addresses = []
    if (selectedChains.value.includes('THOR')) {
      addresses[0] = await new Promise((resolve, reject) =>
        window.xfi.thorchain.request(
          { method: 'request_accounts', params: [] },
          (error, response) => (error ? reject(error) : resolve(response[0])),
        ),
      )
    }

    if (selectedChains.value.includes('ETH') || selectedChains.value.includes('ARB')) {
      const provider = new BrowserProvider(window.xfi.ethereum)
      addresses[1] = (await provider.getSigner()).address
    }

    if (selectedChains.value.includes('BTC')) {
      addresses[2] = await new Promise((resolve, reject) =>
        window.xfi.bitcoin.request({ method: 'request_accounts', params: [] }, (error, response) =>
          error ? reject(error) : resolve(response[0]),
        ),
      )
    }

    if (selectedChains.value.includes('KUJI')) {
      const kujiKeplr = window.xfi.keplr
      await kujiKeplr.enable('kaiyo-1')
      const offlineSigner = kujiKeplr.getOfflineSigner('kaiyo-1')
      const accounts = await offlineSigner.getAccounts()
      addresses[3] = accounts[0].address
    }

    if (selectedChains.value.includes('MAYA')) {
      addresses[4] = await new Promise((resolve, reject) =>
        window.xfi.mayachain.request(
          { method: 'request_accounts', params: [] },
          (error, response) => (error ? reject(error) : resolve(response[0])),
        ),
      )
    }
    addresses[5] = addresses[1]

    await setXDefiOnlyWallets(window.xfi, addresses)
    hideConnectWalletModal()
  } catch (e) {
    captureException(`Couldn't connect xdefi: ${e}`)
    alert("Couldn't connect xdefi")
  }

  function setXDefiOnlyWallets(xDefiObject, addresses) {
    // selectedChains.value = supportedChainsByWallet.xdefi
    const constructedWallets = selectedChains.value
      .map((chain) => {
        if (!isChainConnected.value(chain)) {
          return new WALLETS.Xdefi(chain, xDefiObject, addresses[xDefiAddressIndexes[chain]])
        } else {
          return undefined // * explicit return to satisfy lint rule
        }
      })
      .filter((wallet) => wallet !== undefined)

    selectedChains.value = []

    setWallets([...wallets.value, ...constructedWallets])
    localStorage.setItem('lastWallet', 'xdefi') // use to reconnect on page load
    connectedWallets.value.push('xdefi')
    refetchBalances()
    analytics.track('wallet-connected', {
      wallet: 'xdefi',
    })
  }
}

// TODO: move to wallets/keystore.ts
async function setKeystoreWallets(mnemonic) {
  // initialize constructed wallets based on the selected chains
  selectedChains.value = supportedChainsByWallet.keystore
  const constructedWallets = selectedChains.value.map((chain) => {
    if (!isChainConnected.value(chain)) {
      return new WALLETS.Keystore(chain, mnemonic)
    } else {
      return undefined // * explicit return to satisfy lint rule
    }
  })
  // Initialize each wallet
  await Promise.all(constructedWallets.map((wallet) => wallet.initialize()))
  selectedChains.value = []
  setWallets([...wallets.value, ...constructedWallets])
  localStorage.setItem('lastWallet', 'keystore') // use to reconnect on page load
  connectedWallets.value.push('keystore')
}

// TODO: move to wallets/keystore.ts
async function onConnectKeyStoreSubmit({
  devMode = false,
  refetchBalances,
}: {
  devMode: boolean
  refetchBalances: () => void
}) {
  try {
    const mnemonic = await decryptFromKeystore(JSON.parse(keystore.value), keystorePassword.value)
    // setPhrase(mnemonic)
    await setKeystoreWallets(mnemonic)
    connectWalletIsVisible.value = false
    localStorage.setItem('keystore', keystore.value)
    refetchBalances()
    keyStoreFileName.value = ''
    keystore.value = ''
    keystorePassword.value = ''
    connectedWallets.value.push('keystore')
    analytics.track('wallet-connected', {
      wallet: 'keystore',
    })
  } catch (e) {
    captureException(`Invalid password or keystore provided: ${e}`)
    if (!devMode) {
      alert('Invalid password or keystore provided')
    }
  }
}

// TODO: move to wallets/keystore.ts
function downloadKeystore(keystore, address) {
  const url = window.URL.createObjectURL(
    new Blob([JSON.stringify(keystore, null, 2)], {
      type: 'application/json',
    }),
  )
  const a = document.createElement('a')
  a.style.display = 'none'
  a.href = url
  a.download = `keystore-${address.slice(-4)}.json`
  document.body.appendChild(a)
  a.click()
  window.URL.revokeObjectURL(url)
}

// TODO: move to wallets/keystore.ts
async function createAndDownloadKeystore() {
  createNewKeystoreMnemonic.value = generatePhrase()
  const keystore = await encryptToKeyStore(
    createNewKeystoreMnemonic.value,
    createNewKeystorePassword.value,
  )
  createNewKeystorePassword.value = ''
  await setKeystoreWallets(createNewKeystoreMnemonic.value)
  tab.value = 'new-keystore-success'
  const mayaWallet = new WALLETS.Keystore('MAYA', createNewKeystoreMnemonic.value)
  await mayaWallet.initialize()
  downloadKeystore(keystore, mayaWallet.address)
  analytics.track('wallet-connected', {
    wallet: 'keystore',
    type: 'new',
  })
}

// TODO: move to wallets/keystore.ts
async function recoverKeystore() {
  const mnemonic = recoverMnemonic.value.replace(/\s+/g, ' ')
  if (!validatePhrase(mnemonic)) {
    alert('Invalid phrase entered')
    return
  }
  const keystore = await encryptToKeyStore(mnemonic, recoverKeystorePassword.value)
  recoverKeystorePassword.value = ''
  recoverMnemonic.value = ''
  // setPhrase(mnemonic)
  await setKeystoreWallets(mnemonic)
  const mayaWallet = new WALLETS.Keystore('MAYA', mnemonic)
  await mayaWallet.initialize()

  downloadKeystore(keystore, mayaWallet.address)
  connectWalletIsVisible.value = false
  balancesQuery.refetch()
  analytics.track('wallet-connected', {
    wallet: 'keystore',
    type: 'recovered',
  })
}

// TODO: move to wallets/talisman.ts
async function onConnectSelectSubmitTalisman() {
  // get an array of wallets which are installed
  const installedWallets = getWallets().filter((wallet: TalismanWallet) => wallet.installed)
  // get talisman from the array of installed wallets
  const talismanWallet: TalismanWallet | undefined = installedWallets.find(
    (wallet: TalismanWallet) => wallet.extensionName === 'talisman',
  )
  // enable the wallet
  if (talismanWallet) {
    await talismanWallet.enable('El Dorado')
    talismanWallet.subscribeAccounts((accounts: WalletAccount[] | undefined) => {
      if (accounts) {
        const dotAccount = accounts.find((account: WalletAccount) => account.type === 'sr25519')
        if (dotAccount) {
          const signer = dotAccount.signer
          const wallet = new WALLETS.Talisman('POLKADOT', dotAccount.address, signer)
          // wallet.initialize()
          selectedChains.value = []
          setWallets([...wallets.value, wallet])
          localStorage.setItem('lastWallet', 'talisman') // use to reconnect on page load
          connectedWallets.value.push('talisman')
          balancesQuery.refetch()
          hideConnectWalletModal()
          analytics.track('wallet-connected', {
            wallet: 'talisman',
          })
        }
      }
    })
  }
}

// TODO: move to wallets/phantom.ts
async function connectPhantom() {
  if ('phantom' in window) {
    const provider = window.phantom.solana
    if (provider.isPhantom) {
      let connection
      try {
        connection = await provider.connect({ onlyIfTrusted: true })
      } catch (e) {
        connection = await provider.connect()
      }
      if (!connection.publicKey) {
        alert('Unable to connect to Phantom')
      }
      const address = connection.publicKey.toString()
      const wallet = new WALLETS.Phantom('SOLANA', address, provider)
      selectedChains.value = []
      setWallets([...wallets.value, wallet])
      localStorage.setItem('lastWallet', 'phantom') // use to reconnect on page load
      connectedWallets.value.push('phantom')
      balancesQuery.refetch()
      hideConnectWalletModal()
      analytics.track('wallet-connected', {
        wallet: 'phantom',
      })
    } else {
      window.open('https://phantom.app/', '_blank')
    }
  } else {
    window.open('https://phantom.app/', '_blank')
  }
}

const hasClipboardAccess = async () => {
  const permission = await navigator.permissions.query({
    name: 'clipboard-write' as PermissionName,
  })
  return permission.state === 'granted'
}
const selectMnemonic = async (e: Event) => {
  if (
    e.currentTarget instanceof HTMLTextAreaElement ||
    e.currentTarget instanceof HTMLInputElement
  ) {
    e.currentTarget.select()
    if (await hasClipboardAccess()) {
      navigator.clipboard.writeText(createNewKeystoreMnemonic.value)
    } else {
      alert('Unable to copy passphrase to clipboard, please copy it manually.')
    }
  }
}
const closeConnectWallet = () => {
  connectWalletIsVisible.value = false
}

watch([connectWalletIsVisible], () => {
  if (!connectWalletIsVisible.value) {
    // We need the timeout for the dialog animation to finish before switching tabs
    setTimeout(() => {
      tab.value = 'xdefi'
      keystorePassword.value = ''
      createNewKeystoreMnemonic.value = ''
      createNewKeystorePassword.value = ''
      recoverKeystorePassword.value = ''
      recoverMnemonic.value = ''
    }, 500)
  }
})

// For faster balance loading, fetch balances once, once lpPools and Routes are loaded
// TODO build lpPools and Routes from the same api response, then only need to watch one
const routeStore = useRouteStore()
const { routes, lpPools } = storeToRefs(routeStore)
const unwatchRoutes = watch(routes, () => {
  refetchBalancesIfEmpty()
  unwatchRoutes()
})
const unwatchlpPools = watch(lpPools, () => {
  refetchBalancesIfEmpty()
  unwatchlpPools()
})
</script>

<style scoped>
.disconnect-button,
.connect-button {
  display: inline-flex;
  padding: 8px 16px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 8px;
  background: var(--dark-00);
  color: #fff;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 32px;
  position: fixed;
  right: 16px;
  text-transform: none;
  letter-spacing: normal;
  cursor: pointer;
  z-index: 49;
}

.connected-button-container {
  display: inline-flex;
  flex-direction: row;
  position: fixed;
  right: 16px;
  gap: 16px;
  z-index: 9999;
}
.connected-button-container button {
  position: relative;
}
.connect-wallet-modal {
  border-radius: 16px;
  border: 1px solid var(--dark-2);
  background: var(--dark-00);
  box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.24);
  padding: 0px;
  display: flex;
  flex-direction: row;
  max-width: 800px;
  width: 800px;
}
.connect-wallet-modal .chains label {
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
}
.connect-wallet-modal .wallets {
  border-radius: 16px 0px 0px 16px;
  border: 1px solid var(--dark-2);
  background: var(--dark-00, rgba(40, 40, 40, 0.88));
  padding: 24px 36px;
}
.connect-wallet-modal .wallets-mobile {
  display: none;
}
.connect-wallet-modal .chains {
  padding: 24px 16px;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-grow: 1;
  flex-direction: row;
  flex-wrap: wrap;
}
.chains .switch {
  display: flex;
  gap: 6px;
}
.connect-wallet-modal .chains button {
  width: 264px;
}
.chain-icons {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin: 32px 92px;
  justify-content: flex-start;
}
.chain-icon {
  position: relative;
  padding: 10px;
  aspect-ratio: 1;
}
.chain-icon i {
  top: 0px;
  right: 0px;
  position: absolute;
  z-index: 1;
  margin-right: 8px;
  color: white;
  background: #398200;
  border-radius: 9px;
  height: 18px;
  width: 18px;
}
.chain-icon img {
  border-radius: 32px;
  aspect-ratio: 1;
}
.chain-icon.selected img {
  outline-offset: -1px;
  outline: 2px solid var(--green-1);
}
.chain-icon.disabled img {
  opacity: 0.5;
}
.connect-wallet-modal .chains .select-all {
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
}
.connect-wallet-modal .chains .title {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
  flex-direction: column;
  gap: 22px;
}
.connect-wallet-modal .wallet {
  display: flex;
  width: 277px;
  padding: 16px;
  height: 60px;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  border: 1px solid var(--dark-2);
  margin-bottom: 24px;
  text-align: center;
}
.connect-wallet-modal .wallet div {
  width: 212px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 12px;
}
.connect-wallet-modal .wallet:hover,
.connect-wallet-modal .wallet.selected {
  border-radius: 8px;
  border: 1px solid var(--yellow-0);
  background: var(--background-01);
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
}
.connect-wallet-modal .title {
  text-align: left;
  margin: 8px 0 24px 0;
}
.connect-wallet-modal h4 {
  color: #fff;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: -0.5px;
}
.connect-wallet-modal .connect-icon {
  display: flex;
  width: 24px;
  height: 24px;
  flex-shrink: 0;
  border-radius: 16px;
  background: #828282;
  align-items: center;
  justify-content: center;
}
.connect-wallet-modal .connect-icon.connected {
  background: #398200;
}
.connect-wallet-modal .keystore-input {
  width: 263px;
  height: 39px;
  flex-shrink: 0;
  border-radius: 8px;
  border: 1px solid var(--background-03);
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-size: 14px;
  background: none;
  color: white;
  padding: 12px 18px;
  margin-bottom: 12px;
}
.connect-wallet-modal button.disabled {
  background: #636363;
  cursor: not-allowed;
}
.connect-wallet-modal .keystore-input::placeholder {
  color: white;
}
.modal-content-keystore {
  cursor: auto;
  margin: 18vh auto;
  max-width: 400px;
  padding: 16px;
  border-radius: 12px;
  border: 1px solid #ffef00;
  background: #333333;
  border: 1px solid #636363;
}

@media only screen and (max-width: 767px) {
  .connect-wallet-modal {
    width: 100%;
    margin: 74px 0;
    max-width: 100%;
    overflow: scroll;
    flex-direction: column;
  }
  .connect-wallet-modal .wallets-mobile {
    display: flex;
    flex-direction: column;
  }
  .modal-content {
    max-height: 68vh;
  }
  .connect-wallet-modal .wallets {
    display: none;
  }
  .connect-wallet-modal .wallets-mobile {
    background: var(--dark-00);
  }
  .connect-wallet-modal .wallets-mobile .wallets-mobile-row {
    overflow-x: auto;
    display: flex;
    padding: 0 12px 0 12px;
  }
  .connect-wallet-modal .wallets-mobile .wallet {
    flex: 1;
    padding: 8px;
    margin: auto;
    gap: 8px;
    border: none;
    border-radius: 8px 8px 0 0;
    background: none;
    box-shadow: none;
  }
  .connect-wallet-modal .wallets-mobile .wallet.selected {
    background: var(--background-01);
  }
  .connect-wallet-modal .wallets-mobile .title {
    margin: 24px 16px;
    text-align: center;
  }
  .connect-wallet-modal .wallets-mobile .wallet div {
    width: auto;
  }
  .chains {
    background: var(--background-01);
  }
  .connect-wallet-modal .chain-icons {
    display: grid;
    grid-auto-flow: column;
    margin: 10px;
    width: 100%;
    overflow: scroll;
    padding: 8px 0 0 0;
    gap: 2px;
  }
  .connect-button,
  .disconnect-button {
    display: none;
  }
}
</style>
