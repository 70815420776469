import { KeystoreWallet } from './keystore'
import { XDefiWallet } from './xdefi'
import { TalismanWallet } from './talisman'
import { PhantomWallet } from './phantom'

export const WALLETS = {
  Keystore: KeystoreWallet,
  Xdefi: XDefiWallet,
  Talisman: TalismanWallet,
  Phantom: PhantomWallet,
}

export type DepositWallet = KeystoreWallet | XDefiWallet
export type WalletType = DepositWallet | PhantomWallet | TalismanWallet
