<template>
  <v-layout v-if="$route.path != '/'" class="rounded rounded-md h-screen w-screen">
    <AppHeader />
    <slot />
    <AppFooter />
  </v-layout>
  <v-layout v-else>
    <slot />
  </v-layout>
</template>
<style scoped>
.w-screen {
  width: 100vw !important;
}
.h-screen {
  height: 100vh !important;
}
.v-layout {
  display: block;
}
</style>
