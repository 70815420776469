import { ApiPromise, WsProvider } from '@polkadot/api'
import type { Signer } from '@polkadot/api/types'
import { BaseWallet } from './base'

export class TalismanWallet extends BaseWallet {
  signer: Signer

  constructor(chain: string, address: string, signer: unknown) {
    super(chain)
    this.address = address
    this.signer = signer as Signer
  }

  async transfer({ to, amount }) {
    // const assetName = asset || CHAINS_NATIVE_ASSET[this.chain]
    const amountStr = (parseFloat(amount) * 1e10).toFixed(0)
    const provider = new WsProvider('wss://rpc.polkadot.io')
    const api = await ApiPromise.create({ provider })
    api.setSigner(this.signer)
    const txHash = await api.tx.balances
      .transferKeepAlive(to, parseInt(amountStr))
      .signAndSend(this.address)
    return txHash
  }
}
