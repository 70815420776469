import {
  Connection,
  PublicKey,
  SystemProgram,
  Transaction,
  VersionedTransaction,
} from '@solana/web3.js'

import {
  TOKEN_PROGRAM_ID,
  createAssociatedTokenAccountInstruction,
  createTransferInstruction,
  getAssociatedTokenAddress,
} from '@solana/spl-token'

import { BigNumber } from 'bignumber.js'
import { type SolanaTransaction } from 'rango-sdk-basic'
import { BaseWallet } from './base'

interface TransferParams {
  asset: string
  amount: number
  to: string
  splTokenMint?: string // Add optional SPL token mint address
}

export class PhantomWallet extends BaseWallet {
  provider: any

  constructor(chain: string, address: string, provider: any) {
    super(chain)
    this.address = address
    this.provider = provider
  }

  async transfer(params: TransferParams) {
    const suffix = params.splTokenMint ? `--${params.splTokenMint}` : ''

    const fullAsset = params.asset.toUpperCase() + suffix

    const assetDecimals = getAssetDecimals(fullAsset)

    const lamports = new BigNumber(params.amount).times(10 ** assetDecimals).integerValue()

    const fromPubkey = new PublicKey(this.address)
    const toPubkey = new PublicKey(params.to)
    const transaction = new Transaction()

    const nodeUrl = 'https://node.eldorado.market/helius'
    const connection = new Connection(nodeUrl)

    const lastBlockhash = (await connection.getLatestBlockhash('finalized')).blockhash
    transaction.recentBlockhash = lastBlockhash
    transaction.feePayer = fromPubkey

    if (params.splTokenMint) {
      // Transfer SPL token
      const mintPublicKey = new PublicKey(params.splTokenMint)
      const fromTokenAccount = await getAssociatedTokenAddress(mintPublicKey, fromPubkey)
      const toTokenAccount = await getAssociatedTokenAddress(mintPublicKey, toPubkey)

      // Ensure the recipient has a token account
      const receiverAccount = await connection.getAccountInfo(toTokenAccount)
      if (!receiverAccount) {
        transaction.add(
          createAssociatedTokenAccountInstruction(
            fromPubkey,
            toTokenAccount,
            toPubkey,
            mintPublicKey,
          ),
        )
      }

      transaction.add(
        createTransferInstruction(
          fromTokenAccount,
          toTokenAccount,
          fromPubkey,
          lamports.toNumber(),
          [],
          TOKEN_PROGRAM_ID,
        ),
      )
    } else {
      // Transfer SOL
      transaction.add(
        SystemProgram.transfer({
          fromPubkey,
          toPubkey,
          lamports: lamports.toNumber(),
        }),
      )
    }

    const { signature } = await this.provider.signAndSendTransaction(transaction)
    return signature
  }

  async signAndSendTransaction(tx: SolanaTransaction): Promise<string> {
    const nodeUrl = 'https://node.eldorado.market/helius'
    const connection = new Connection(nodeUrl)
    let versionedTransaction: VersionedTransaction | undefined
    let transaction: Transaction | undefined
    if (tx.serializedMessage != null) {
      if (tx.txType === 'VERSIONED') {
        versionedTransaction = VersionedTransaction.deserialize(
          new Uint8Array(tx.serializedMessage),
        )
        const blockhash = (await connection.getLatestBlockhash('confirmed')).blockhash
        if (blockhash) versionedTransaction.message.recentBlockhash = blockhash
      } else if (tx.txType === 'LEGACY') {
        transaction = Transaction.from(Buffer.from(new Uint8Array(tx.serializedMessage)))
        transaction.feePayer = new PublicKey(tx.from)
        transaction.recentBlockhash = undefined
      }
    }
    const finalTx: Transaction | VersionedTransaction = transaction || versionedTransaction
    if (!finalTx) throw new Error('Error creating transaction')

    const { signature } = await this.provider.signAndSendTransaction(finalTx, {
      preflightCommitment: 'confirmed',
    })
    // const status = await connection.getSignatureStatus(signature)

    return signature
  }
}
